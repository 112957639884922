<template>
  <div>

    <slider-sidebar
      v-model="isSliderHandlerSidebarActive"
      :slider="sliderData"
      :clear-data="resetSliderData"
      @add-slider="addSlider"
      @update-slider="updateSlider"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de sliders</h3> <small> (Hay {{ totalSliders }} sliders)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-can-permissions="'Crear slider'"
                variant="primary"
                @click="isSliderHandlerSidebarActive = true"
              >
                <span class="text-nowrap">Crear</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSliderListTable"
        class="position-relative"
        :items="fetchSliders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: photo desktop -->
        <template #cell(photo_desktop)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                rounded
                thumbnail
                fluid
                :src="data.item.photo_desktop"
                alt="Blog dramox"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Photo mobil -->
        <template #cell(photo_mobile)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                v-if="data.item.photo_mobile"
                rounded
                thumbnail
                fluid
                :src="data.item.photo_mobile"
                alt="Blog dramox"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Photo mobil -->
        <template #cell(title)="data">
          <span class="font-weight-bold d-block text-wrap">
            {{ data.item.title }}
          </span>
          <span>{{ data.item.subtitle }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span v-can-permissions="'Editar slider'">
              <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editSlider(data.item)"
              />
              <b-tooltip
                title="Editar"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />

            </span>

            <span v-can-permissions="'Eliminar slider'">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="deleteSlider(data.item)"
              />
              <b-tooltip
                title="Eliminar"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
            </span>

          </div>
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BTooltip, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import router from '@/router'
import elementStoreModule from '@/views/dramox/elements/elementStoreModule'
import elementHandler from '@/views/dramox/elements/elementHandler'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SliderSidebar from '@/views/dramox/elements/slider/sidebar/sliderSidebar'

export default {
  components: {
    SliderSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    // BAvatar,
    BTooltip,
    BButton,
    BImg,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-elements'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, elementStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }
    const blankSliderData = {
      title: '',
      subtitle: '',
      urlButton: '',
      titleButton: '',
      imageDesktop: null,
      imageMobile: null,
      showImageDesktop: null,
      showImageMobile: null,
    }
    const sliderData = ref(JSON.parse(JSON.stringify(blankSliderData)))

    const resetSliderData = () => {
      sliderData.value = JSON.parse(JSON.stringify(blankSliderData))
    }

    const isSliderHandlerSidebarActive = ref(false)

    const {
      tableColumns,
      totalSliders,
      refSliderListTable,
      refetchData,
      fetchSliders,
    } = elementHandler()

    const editSlider = item => {
      const objSlider = JSON.parse(JSON.stringify(blankSliderData))
      const { photo_desktop: imageDesktop, photo_mobile: imageMobile, ...rest } = item
      Object.assign(objSlider, rest)
      Object.assign(objSlider, { showImageDesktop: imageDesktop, showImageMobile: imageMobile })
      sliderData.value = objSlider
      isSliderHandlerSidebarActive.value = true
    }

    const addSlider = item => {
      const formData = new FormData()
      formData.append('title', item.title)
      formData.append('subtitle', item.subtitle)
      formData.append('urlButton', item.urlButton)
      formData.append('titleButton', item.titleButton)
      formData.append('imageDesktop', item.imageDesktop)
      formData.append('imageMobile', item.imageMobile)

      store.dispatch('app-elements/addSlider', formData)
        .then(() => {
          refetchData()
          showNotification({ title: 'Slider creado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateSlider = item => {
      const formData = new FormData()
      formData.append('title', item.title)
      formData.append('subtitle', item.subtitle)
      formData.append('urlButton', item.urlButton)
      formData.append('titleButton', item.titleButton)
      formData.append('imageDesktop', item.imageDesktop)
      formData.append('imageMobile', item.imageMobile)

      store.dispatch('app-elements/updateSlider', { id: item.id, body: formData })
        .then(() => {
          refetchData()
          showNotification({ title: 'Slider actualizado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteSlider = item => {
      store.dispatch('app-elements/deleteSlider', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Slider eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        }).catch(e => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      isSliderHandlerSidebarActive,
      // fetchUsers,
      tableColumns,

      totalSliders,
      refSliderListTable,

      refetchData,
      fetchSliders,

      sliderData,
      editSlider,
      deleteSlider,
      addSlider,
      updateSlider,

      resetSliderData,

    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.list-group-item {
  transition: all 1s
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
