import { ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function elementHandler() {
  // Use toast
  const toast = useToast()

  const refSliderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'order_slider', label: 'Orden', sortable: false },
    { key: 'title', label: 'Título', sortable: false },
    { key: 'photo_desktop', label: 'Foto Desktop', sortable: false },
    { key: 'photo_mobile', label: 'Foto Móvil', sortable: false },
    { key: 'urlButton', label: 'Url boton', sortable: false },

    { key: 'actions', label: 'Acciones' },
  ]

  const perPage = ref(10)
  const totalSliders = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const refetchData = () => {
    refSliderListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchSliders = (ctx, callback) => {
    store
      .dispatch('app-elements/fetchSliders', {
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const data = response.data.payload.results

        callback(data)
        totalSliders.value = data.length
      })
      .catch((e) => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sliders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSliders,

    tableColumns,
    perPage,
    currentPage,
    totalSliders,
    perPageOptions,
    refSliderListTable,

    refetchData,
  }
}
