<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isSliderHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-slider-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ ObjectLocal.id ? 'Editar slider' : 'Agregar slider' }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Titulo"
              rules="required"
            >
              <b-form-group
                label="Titulo"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="ObjectLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Sub Title -->
            <validation-provider
              #default="validationContext"
              name="Sub titulo"
              rules="required"
            >
              <b-form-group
                label="Sub titulo"
                label-for="subtitle"
              >
                <b-form-input
                  id="subtitle"
                  v-model="ObjectLocal.subtitle"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title button -->
            <validation-provider
              #default="validationContext"
              name="Titulo boton"
              rules="required"
            >
              <b-form-group
                label="Titulo boton"
                label-for="title-button"
              >
                <b-form-input
                  id="title-button"
                  v-model="ObjectLocal.titleButton"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Url button -->
            <validation-provider
              #default="validationContext"
              name="URL boton"
              rules="required"
            >
              <b-form-group
                label="URL boton"
                label-for="url-button"
              >
                <b-form-input
                  id="url-button"
                  v-model="ObjectLocal.urlButton"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder=""
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Image Desktop -->
            <validation-provider
              #default="validationContext"
              name="Imagen Desktop"
              :rules=" { required : !ObjectLocal.id }"
            >
              <b-form-group
                label="Imagen Desktop"
                label-for="image-desktop"
              >
                <b-form-file
                  id="image-desktop"
                  ref="refInputElDesktop"
                  v-model="ObjectLocal.imageDesktop"
                  :state="getValidationState(validationContext)"
                  placeholder="(1920x800px)"
                  accept="image/*"
                  @input="renderDesktop"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="ObjectLocal.showImageDesktop"
              class="my-1"
            >
              <b-img
                thumbnail
                fluid
                :src="ObjectLocal.showImageDesktop"
                alt="Image Desktop"
              />
            </div>

            <!-- Image Mobile -->
            <validation-provider
              #default="validationContext"
              name="Imagen Mobile"
              :rules=" { required : !ObjectLocal.id }"
            >
              <b-form-group
                label="Imagen Mobile"
                label-for="image-mobile"
              >
                <b-form-file
                  id="image-mobile"
                  ref="refInputElMobile"
                  v-model="ObjectLocal.imageMobile"
                  :state="getValidationState(validationContext)"
                  placeholder="(778x960px)"
                  accept="image/*"
                  @input="renderMobile"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div
              v-if="ObjectLocal.showImageMobile"
              class="my-1"
            >
              <b-img
                thumbnail
                fluid
                :src="ObjectLocal.showImageMobile"
                alt="Image Mobile"
              />
            </div>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ ObjectLocal.id ? 'Actualizar' : 'Agregar ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormFile, BImg, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import sliderHandlerSidebar from '@/views/dramox/elements/slider/sidebar/sliderHandlerSidebar'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BImg,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSliderHandlerSidebarActive',
    event: 'update:is-slider-handler-sidebar-active',
  },
  props: {
    isSliderHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    slider: {
      type: Object,
      required: true,
    },
    clearData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  setup(props, { emit }) {
    const {
      ObjectLocal,
      resetLocal,
      onSubmit,
    } = sliderHandlerSidebar(toRefs(props), emit)

    const refInputElDesktop = ref(null)
    const refInputElMobile = ref(null)


    const { inputImageRenderer: renderDesktop } = useInputImageRenderer(refInputElDesktop, base64 => {
      // eslint-disable-next-line no-param-reassign
      ObjectLocal.value.showImageDesktop = base64
    })

    const { inputImageRenderer: renderMobile } = useInputImageRenderer(refInputElMobile, base64 => {
      // eslint-disable-next-line no-param-reassign
      ObjectLocal.value.showImageMobile = base64
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetLocal, props.clearData)

    return {
      // Add New
      ObjectLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      renderDesktop,
      refInputElDesktop,
      refInputElMobile,
      renderMobile,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
